import React, { Fragment, useEffect, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Home16 } from "@carbon/icons-react"
import { useTranslation } from "react-i18next"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const websitesMapping = require("gatsby-theme-rothenberger/config/websites.js")
const websites = websitesMapping["websitesMapping"]

const MetaNav = () => {
  const [currentWebsite, setCurrentWebsite] = useState(null)
  const [otherWebsites, setOtherWebsites] = useState(null)
  const { t, i18n } = useTranslation()

  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentWebsite(
        websites.find(
          (w) =>
            w.url.replace("https://rothenberger.com", "").split("/")[1] ===
            window.location.pathname.split("/")[1]
        ) || null
      )
      setOtherWebsites(websites)
    }
  }, [])

  if (!currentWebsite) {
    return null
  }

  return (
    <div className="hidden w-full text-gray-400 bg-gray-800 md:block">
      <div className="flex flex-row mx-auto max-w-screen-2xl">
        <Link
          className="text-gray-200 btn hover:text-white"
          link="https://rothenberger.com/de-de/"
        >
          Zur ROTHENBERGER Website
        </Link>
        <div className="inline-block ml-auto"></div>
      </div>
    </div>
  )
}

export default MetaNav

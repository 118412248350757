import React from "react"
import CloudinaryImage from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Images/CloudinaryImage"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import ProductListingPrice from "../../../components/atoms/ProductPrice"

const ProductCard = ({ item }) => {
  if (item.image === "undefined") {
    item.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }

  return (
    <Link className="flex flex-col w-full group" link={item.link}>
      <div className="border border-gray-100 aspect-square block w-full relative">
        <CloudinaryImage
          className="w-full h-auto min-h-full"
          image={item.image.src}
          alt={item.image.alt}
          aspectRatio="1by1"
          fitIn="true"
          fillColor="ffffff"
        />
        {item.price?.compareAtPrice && (
          <span className="absolute top-0 right-0 text-lg leading-snug px-4 py-2 bg-primary text-white">
            %
          </span>
        )}
      </div>
      <div className="flex-1 p-4 -mt-px border border-gray-100">
        <div className="flex flex-col h-full">
          <span className="font-bold group-hover:text-primary transition-color duration-300 ease-in-out hyphens-auto">
            {item.headline}
          </span>
          <p className="mt-2 prose-sm text-gray-500">{item.text}</p>
          <ProductListingPrice
            prices={item.price}
            className="mt-2 flex-1 flex flex-col justify-end"
          />
        </div>
      </div>
    </Link>
  )
}

export default ProductCard

import React, { useContext } from "react"
import { ShoppingCart20 } from "@carbon/icons-react"
import CartContext from "../../context/CartContext"

const CartButtonMobile = () => {
  const { setShowCart, cartItemCount } = useContext(CartContext)

  return (
    <button
      className="relative align-middle btn pr-6"
      type="button"
      onClick={() => setShowCart(true)}
    >
      <ShoppingCart20 className="inline-block -mt-0.5 align-middle cursor-pointer" />
      {cartItemCount > 0 && (
        <span
          className="text-center py-1 absolute leading-none top-0.5 right-[0.75rem] bg-primary rounded-full text-white w-5 h-5 text-xs cursor-pointer"
          onClick={() => setShowCart((showCart) => !showCart)}
        >
          {cartItemCount}
        </span>
      )}
    </button>
  )
}

export default CartButtonMobile

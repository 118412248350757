import React, { Fragment, useEffect, useState, useContext } from "react"
import CloudinaryImage from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Images/CloudinaryImage"
import { TrashCan20 } from "@carbon/icons-react"
import ProductAmountStepper from "../atoms/ProductAmountStepper"
import {
  ProductCartItemPrice,
  simpleCartItemTotalPrice,
} from "../atoms/ProductPrice"

const CartItem = ({
  item,
  handleDecrementQuantity,
  handleIncrementQuantity,
  handleRemoveLineItem,
}) => {
  return (
    <>
      <div className="flex flex-wrap w-full items-start md:items-center my-4">
        <div className="flex-auto flex w-2/3">
          <div className="w-20 flex-none">
            <div>
              <CloudinaryImage
                fillColor="ffffff"
                aspectRatio="1by1"
                image={
                  item?.variant?.image?.src ||
                  process.env.GATSBY_IMG_PLACEHOLDER
                }
                lazyLoad={false}
              />
            </div>
          </div>
          <div className="px-3 flex-auto">
            <h3 className="color-primary text-sm md:text-base">{item.title}</h3>
          </div>
        </div>
        <div className="flex font-medium text-gray-900 w-full pt-4 md:pt-0 md:w-1/3">
          <div className="flex flex-wrap justify-center w-32">
            <ProductAmountStepper
              id={`amount-stepper-cart-item-${item.variant.id}`}
              label="Anzahl"
              inputValue={item.quantity}
              min={1}
              max={1000}
              step={1}
              onDecrement={(e) => handleDecrementQuantity(item.variant.id)}
              onIncrement={(e) => handleIncrementQuantity(item.variant.id)}
            />
            <div className="text-center mt-3 w-full text-xs">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  handleRemoveLineItem(item.id)
                }}
              >
                <TrashCan20 className="inline-block -mt-1" /> Entfernen
              </button>
            </div>
          </div>
          <div className="text-right w-full">
            <span className="text-sm text-gray-500">
              Einzelpreis:{" "}
              <ProductCartItemPrice
                className="inline-block"
                price={item.variant.priceV2}
              />
            </span>
            <ProductCartItemPrice
              className="text-lg"
              price={{
                amount: simpleCartItemTotalPrice(
                  item.variant.priceV2.amount,
                  item.quantity
                ),
                currencyCode: item.variant.priceV2.currencyCode,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CartItem

import React from "react"

export const simpleCartItemTotalPrice = (amount, quantity) => {
  return (amount * quantity).toFixed(2).toString().replace(".", ",")
}

const currencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case "EUR":
      return "€"
      break
    default:
      return currencyCode
      break
  }
}

const formatPrice = (price) => {
  return `${price.amount.toString().replace(".", ",")}  ${currencySymbol(
    price.currencyCode
  )}`
}

export const ProductListingPrice = ({ prices, className }) => {
  if (prices?.compareAtPrice) {
    return (
      <div className={className}>
        <div className="border-t border-gray-50 pt-2 text-center">
          <span className="text-primary">{formatPrice(prices.price)}</span>
          <span className="mx-1.5 text-gray-400">statt</span>
          <span className="line-through decoration-2 text-gray-400">
            {formatPrice(prices.compareAtPrice)}
          </span>
        </div>
      </div>
    )
  } else {
    return (
      <div className={className}>
        <span className="text-primary border-t border-gray-50 pt-2 text-center">
          {formatPrice(prices.price)}
        </span>
      </div>
    )
  }
}

export const ProductDetailPrice = ({ prices, className }) => {
  if (prices?.compareAtPrice) {
    return (
      <div className={className}>
        <div className="pt-2">
          <div className="text-primary font-bold">
            {formatPrice(prices.price)}
          </div>
          <div className="decoration-2 text-gray-400 text-xl">
            statt{" "}
            <span className="line-through">
              {formatPrice(prices.compareAtPrice)}
            </span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={className}>
        <span className="text-primary font-bold pt-2">
          {formatPrice(prices.price)}
        </span>
      </div>
    )
  }
}

export const ProductCartItemPrice = ({ price, className }) => {
  return (
    <div className={className}>
      <span className="pt-2">{formatPrice(price)}</span>
    </div>
  )
  if (prices?.compareAtPrice) {
    return (
      <div className={className}>
        <div className="pt-2">
          <div className="text-primary font-bold">
            {formatPrice(prices.price)}
          </div>
          <div className="decoration-2 text-gray-400 text-xl">
            statt{" "}
            <span className="line-through">
              {formatPrice(prices.compareAtPrice)}
            </span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={className}>
        <span className="text-primary font-bold pt-2">
          {formatPrice(prices.price)}
        </span>
      </div>
    )
  }
}

export default ProductListingPrice

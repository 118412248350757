import React, { Fragment, useEffect, useState, useContext } from "react"
import { Dialog, Transition, Switch } from "@headlessui/react"
import { navigate } from "gatsby"
import {
  ShoppingCart24,
  Renew20,
  CloseOutline20,
  CaretLeft20,
  CaretRight20,
} from "@carbon/icons-react"
import CartContext from "../../context/CartContext"
import CartItem from "../molecules/CartItem"
import { ProductCartItemPrice } from "../atoms/ProductPrice"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

// TODO: Add translations
// TODO: Add formatPrice function
// TODO: Add removeLineItem function
// TODO: Code splitting

const Cart = () => {
  const [itemCount, setItemCount] = useState(0)
  const [cartIsEmpty, setCartIsEmpty] = useState(true)
  const [userToSConfirmed, setUserToSConfirmed] = useState(false)

  const {
    checkout,
    updateLineItem,
    removeLineItem,
    showCart,
    setShowCart,
    setCartItemCount,
    isWorking,
  } = useContext(CartContext)

  const countLineItems = () => {
    let itemCount = 0
    checkout?.lineItems.map((item) => {
      itemCount += item.quantity
      return null
    })
    setItemCount(itemCount)
    setCartItemCount(itemCount)
  }

  useEffect(() => {
    countLineItems()
    if (itemCount === 0 || itemCount === undefined) {
      setCartIsEmpty(true)
    } else {
      setCartIsEmpty(false)
    }

    return () => {
      setItemCount(0)
      setCartItemCount(0)
      setCartIsEmpty(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, cartIsEmpty, itemCount])

  const lineItems = checkout?.lineItems

  const handleDecrementQuantity = (variantId) => {
    updateLineItem({ variantId: variantId, quantity: -1 })
  }

  const handleIncrementQuantity = (variantId) => {
    updateLineItem({ variantId: variantId, quantity: 1 })
  }

  const handleRemoveLineItem = (variantId) => {
    removeLineItem(variantId)
  }

  return (
    <>
      <div className="relative align-middle btn pr-6">
        <ShoppingCart24
          className="inline-block -mt-0.5 align-middle cursor-pointer"
          onClick={() => setShowCart((showCart) => !showCart)}
        />
        {itemCount > 0 && (
          <span
            className="text-center py-1 absolute leading-none top-0.5 right-[0.75rem] bg-primary rounded-full text-white w-5 h-5 text-xs cursor-pointer"
            onClick={() => setShowCart((showCart) => !showCart)}
          >
            {itemCount}
          </span>
        )}
      </div>

      <Transition.Root show={showCart} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowCart}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-stretch sm:items-center justify-center min-h-full text-center px-3 lg:px-8">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-105"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-105"
              >
                <Dialog.Panel className="flex text-base text-left transform transition w-full max-w-4xl sm:my-8">
                  <form className="w-full relative flex flex-col bg-white pt-6 pb-8 overflow-hidden sm:pb-6 lg:py-8">
                    <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 mb-3">
                      <h2 className="headline headline-h4 text-primary">
                        Ihr Warenkorb
                        {isWorking && (
                          <Renew20 className="animate-spin transform rotate-180 inline-block ml-5 -mt-0.5 align-middle" />
                        )}
                      </h2>
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500 -mt-4 -mr-2"
                        onClick={() => setShowCart(false)}
                      >
                        <CloseOutline20 />
                        <span className="sr-only">Schließen</span>
                      </button>
                    </div>

                    <section aria-labelledby="cart-heading">
                      <h2 id="cart-heading" className="sr-only">
                        Artikel in Ihrem Warenkorb
                      </h2>

                      {cartIsEmpty && (
                        <div className="pt-8 px-4 sm:px-6 lg:px-8">
                          <p className="mb-4">
                            Derzeit befinden sich keine Artikel in Ihrem
                            Warenkorb.
                          </p>
                        </div>
                      )}

                      {!cartIsEmpty && (
                        <div className="divide-y-4 divide-gray-50 px-4 sm:px-3 lg:px-8">
                          {lineItems?.map((item) => (
                            <div
                              key={item.variant.id}
                              className="flex flex-wrap"
                            >
                              <CartItem
                                item={item}
                                handleDecrementQuantity={(id) =>
                                  handleDecrementQuantity(id)
                                }
                                handleIncrementQuantity={(id) =>
                                  handleIncrementQuantity(id)
                                }
                                handleRemoveLineItem={handleRemoveLineItem}
                              />{" "}
                            </div>
                          ))}
                        </div>
                      )}
                    </section>
                    {!cartIsEmpty && !!checkout?.webUrl && (
                      <section aria-labelledby="summary-heading">
                        <div className="bg-gray-50 py-6 sm:px-3 lg:px-8">
                          <h2 id="summary-heading" className="sr-only">
                            Bestellübersicht
                          </h2>

                          <dl className="py-4 flex items-start justify-between">
                            <dt className="text-base font-medium text-gray-900">
                              <div>Zwischensumme:</div>
                              <span className="text-sm text-gray-500">
                                Hinweis: die Versandkosten werden während des
                                Kaufvorgangs berechnet.
                              </span>
                            </dt>
                            <dd className="text-base font-medium text-gray-900 text-right">
                              <ProductCartItemPrice
                                className="text-xl text-primary font-bold"
                                price={checkout?.totalPriceV2}
                              />
                              <span className="text-sm text-gray-500">
                                <a
                                  className="inline-block hover:text-primary underline decoration-solid"
                                  href={
                                    process.env.GATSBY_RFO_DELIVERY_PAGE_PATH
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  zzgl. Versandkosten
                                </a>
                              </span>
                            </dd>
                          </dl>
                        </div>
                      </section>
                    )}
                    <div className="mt-8 block text-center md:flex md:justify-between px-4 sm:px-6 lg:px-8">
                      <button
                        className="btn mb-3 md:mb-0"
                        onClick={() => setShowCart(false)}
                        type="button"
                      >
                        <CaretLeft20 className="inline-block -mt-1" /> Weiter
                        einkaufen
                      </button>
                      {!cartIsEmpty && !!checkout?.webUrl && (
                        <div className="text-right">
                          <Switch.Group
                            as="div"
                            className="text-sm flex align-middle my-3"
                          >
                            <Switch.Label className="text-gray-900" passive>
                              Ich stimme den{" "}
                              <a
                                className="hover:text-primary underline decoration-solid"
                                href={
                                  process.env.GATSBY_RFO_CONDITIONS_PAGE_PATH
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                AGB
                              </a>{" "}
                              und der{" "}
                              <a
                                className="hover:text-primary underline decoration-solid"
                                href={process.env.GATSBY_RFO_PRIVACY_PAGE_PATH}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Datenschutzerklärung
                              </a>{" "}
                              zu
                            </Switch.Label>
                            <Switch
                              checked={userToSConfirmed}
                              onChange={setUserToSConfirmed}
                              className={`${
                                userToSConfirmed ? "bg-primary" : "bg-gray-200"
                              } relative inline-flex h-6 w-11 items-center rounded-full ml-3 flex-none`}
                            >
                              <span className="sr-only">
                                Enable notifications
                              </span>
                              <span
                                className={`${
                                  userToSConfirmed
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                              />
                            </Switch>
                          </Switch.Group>
                          <button
                            className="btn btn-primary px-14"
                            disabled={!userToSConfirmed}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(
                                checkout.webUrl.replace(
                                  process.env.GATSBY_MYSHOPIFY_API_URL,
                                  process.env.GATSBY_MYSHOPIFY_URL
                                )
                              )
                            }}
                          >
                            Zur Kasse{" "}
                            <CaretRight20 className="inline-block -mt-1" />
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Cart

import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import SearchResultCard from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/SearchResultCard"
import { Close20 } from "@carbon/icons-react"
import { connectHits } from "react-instantsearch-core"
import { useTranslation } from "react-i18next"

// import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import ProductCard from "gatsby-theme-rothenberger/src/components/molecules/ProductCard"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

// Nur zu Debug-Zwecken, um ALLE Such-Ergebnisse zu sehen
const SearchResultsAll = connectHits(({ hits }) => {
  return (
    <>
      {hits.map((hit) => (
        <div>
          {JSON.stringify(hit)}
          <br />
          <br />
        </div>
      ))}
    </>
  )
})

// Suchergebnisse: Nur Seiten (ohne News)
const SearchResultsPages = ({ results }) => {
  return (
    <>
      {results &&
        results.length > 0 &&
        results.map((hit) => (
          <SearchResultCard hit={hit} key={hit.full_slug} />
        ))}
    </>
  )
}

// Suchergebnisse: Nur News-Seiten
const SearchResultsNews = ({ results }) => {
  return (
    <>
      {results &&
        results.length > 0 &&
        results.map((hit) => (
          <SearchResultCard hit={hit} key={hit.full_slug} />
        ))}
    </>
  )
}

// Suchergebnisse: Nur Kategorien
const SearchResultsCategories = ({ results }) => {
  if (results && results.length > 0) {
    return (
      <div className="py-4 border border-gray-100">
        {results.map((hit) => (
          <div className="block" key={hit.full_slug}>
            <Link
              className="inline-block py-2 text-sm px-grid hover:text-primary"
              to={hit.full_slug}
            >
              <span dangerouslySetInnerHTML={{ __html: hit.name }} />
            </Link>
          </div>
        ))}
      </div>
    )
  } else {
    return <></>
  }
}

// Suchergebnisse: Nur Varianten
const SearchResultsVariants = ({ results }) => {
  const { t } = useTranslation()
  const [showResultLimit, setShowResultLimit] = useState(3)
  const [displayResults, setDisplayResults] = useState(results)

  const showMoreResultsHandler = (currentLimit) => {
    setShowResultLimit(currentLimit + currentLimit)
  }

  useEffect(() => {
    if (results && showResultLimit) {
      setDisplayResults(results.slice(0, showResultLimit))
    }
  }, [results, showResultLimit])

  if (displayResults && displayResults.length > 0) {
    return (
      <>
        <Headline
          className="block group-hover:text-primary-hover"
          tagName="h2"
          headlineLevel="h4"
          headline={`${t("generic.tabs.variants")} (${results.length})`}
        />
        <div className="flex flex-row flex-wrap -mx-grid">
          {displayResults.map((hit) => (
            <div
              className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
              key={hit.fullSlug}
            >
              {hit.image_url}
              <ProductCard
                item={{
                  headline: hit.name,
                  text: hit.groupName,
                  image: {
                    src: hit.image_url,
                    alt: hit.name,
                  },
                  link: hit.full_slug,
                  price: {
                    price: hit.price,
                    compareAtPrice: hit.compareAtPrice,
                  },
                }}
              />
            </div>
          ))}
          {displayResults &&
            displayResults.length > 0 &&
            showResultLimit < results.length && (
              <div className="w-full text-center">
                <button
                  onClick={(e) => showMoreResultsHandler(showResultLimit)}
                  className="btn btn-primary"
                >
                  {t("generic.search_results.show_more_variants")}
                </button>
              </div>
            )}
        </div>
      </>
    )
  } else {
    return <></>
  }
}

// Suchergebnisse: Nur Produkte
const SearchResultsProducts = ({ results }) => {
  const { t } = useTranslation()
  const [showResultLimit, setShowResultLimit] = useState(3)
  const [displayResults, setDisplayResults] = useState(results)

  const showMoreResultsHandler = (currentLimit) => {
    setShowResultLimit(currentLimit + currentLimit)
  }

  useEffect(() => {
    if (results && showResultLimit) {
      setDisplayResults(results.slice(0, showResultLimit))
    }
  }, [results, showResultLimit])

  if (displayResults && displayResults.length > 0) {
    return (
      <>
        <Headline
          className="block group-hover:text-primary-hover"
          tagName="h2"
          headlineLevel="h4"
          headline={`${t("generic.tabs.products_and_more")} (${
            results.length
          })`}
        />
        <div className="flex flex-row flex-wrap -mx-grid">
          {displayResults.map((hit, index) => (
            <div
              className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
              key={`${hit.fullSlug}-${index}`}
            >
              <ProductCard
                item={{
                  headline: hit.name,
                  text: hit.groupName,
                  image: {
                    src: hit.image_url,
                    alt: hit.name,
                  },
                  variants: "8",
                  link: hit.full_slug,
                  price: {
                    price: hit.price,
                    compareAtPrice: hit.compareAtPrice,
                  },
                }}
              />
            </div>
          ))}
          {displayResults &&
            displayResults.length > 0 &&
            showResultLimit < results.length && (
              <div className="w-full text-center">
                <button
                  onClick={(e) => showMoreResultsHandler(showResultLimit)}
                  className="btn btn-primary"
                >
                  {t("generic.search_results.show_more_products")}
                </button>
              </div>
            )}
        </div>
      </>
    )
  } else {
    return <></>
  }
}

// Konvertiert anhand des 'type' in einen Anzeige-Tab
const typeToTab = (hit) => {
  let type = ""
  // Alle von diesem Typ kommen in den Tab "Produkte (+ mehr)"
  if (
    [
      "product_area",
      "product_line",
      "product_group",
      "product",
      "variant",
      "collection",
    ].includes(hit.type)
  ) {
    type = "products_and_more"
  } else {
    type = hit.type
  }
  return type
}
const tabToTypes = (tab) => {
  let types = []
  if (tab === "products_and_more") {
    types = [
      "product_area",
      "product_line",
      "product_group",
      "product",
      "variant",
    ]
  } else {
    types = [tab]
  }
  return types
}

const SearchTabs = connectHits(
  ({ hits, resultsCategoryState, setResultsCategoryState, query }) => {
    const { t } = useTranslation()

    let c = []
    let cc = []
    hits.forEach((hit) => {
      let type = typeToTab(hit)
      if (!c.includes(type)) {
        c.push(type)
        cc.push({
          name: type,
          count: hits.filter((h) => tabToTypes(type).includes(h.type)).length,
        })
      }
    })

    cc = cc.filter((c) => c.count > 0)

    useEffect(() => {
      if (hits && hits[0]) {
        // console.warn('Set state', hits[0])
        // setResultsCategoryState( typeToTab(hits[0]) )
        if (cc.length === 1) {
          setResultsCategoryState(cc[0].name)
        }
      }
    }, [query, hits])

    return (
      <>
        {cc.map((cat, index) => (
          <button
            key={cat.name}
            className={` btn whitespace-nowrap ml-px ${
              resultsCategoryState === cat.name
                ? "bg-white"
                : "text-gray-200 bg-gray-700"
            }`}
            onClick={() => {
              setResultsCategoryState(cat.name)
            }}
          >
            <>
              {t(`generic.tabs.${cat.name}`)}
              &nbsp; ({cat.count})
            </>
          </button>
        ))}
      </>
    )
  }
)

const SearchResultsOverlay = connectHits(
  ({
    hits,
    showSearchResultsState,
    setShowSearchResultsState,
    isMobile = false,
    query,
  }) => {
    const [resultsCategoryState, setResultsCategoryState] =
      useState("products_and_more")
    const [resultsPrepared, setResultsPrepared] = useState(false)
    const [resultMap, setResultMap] = useState({
      pageResults: null,
      newsResults: null,
      productResults: null,
      categoryResults: null,
    })
    const { t } = useTranslation()

    useEffect(() => {
      if (showSearchResultsState && hits && hits.length > 0) {
        let pageResults = hits.filter((h) => h.type === "page") || null
        let newsResults = hits.filter((h) => h.type === "news") || null
        let productResults = hits.filter((h) => h.type === "product") || null
        let categoryResults =
          hits.filter((h) => h.type === "collection" && h.totalInventory > 0) ||
          null

        setResultMap({
          pageResults: pageResults,
          newsResults: newsResults,
          productResults: productResults,
          categoryResults: categoryResults,
        })
        setResultsPrepared(true)
      } else {
        setResultsPrepared(false)
      }
    }, [showSearchResultsState, hits])

    return (
      <>
        {showSearchResultsState && resultsPrepared && (
          <>
            <Helmet
              htmlAttributes={{
                class: "overflow-x-hidden overflow-hidden",
              }}
              bodyAttributes={{
                class:
                  "theme-default font-body overflow-x-hidden overflow-hidden",
              }}
            />
            <div
              className="absolute z-50 w-full bg-gray-100 top-full"
              style={{
                height: `${
                  isMobile ? "calc(100vh - 3rem)" : "calc(100vh - 8.125rem)"
                }`,
              }}
            >
              <div className="relative pt-10 py-8 mx-auto max-w-screen-2xl px-grid">
                {isMobile === false && (
                  <button
                    className="absolute right-0 p-4 -top-1"
                    onClick={() => {
                      setShowSearchResultsState(false)
                    }}
                  >
                    <Close20 />
                  </button>
                )}
                <div className="flex flex-row overflow-y-auto">
                  <SearchTabs
                    resultsCategoryState={resultsCategoryState}
                    setResultsCategoryState={setResultsCategoryState}
                    query={query}
                  />
                </div>

                {resultsCategoryState === "page" && (
                  <div
                    className=" py-4 bg-white"
                    style={{
                      maxHeight: `${
                        isMobile
                          ? "calc(100vh - 10rem)"
                          : "calc(100vh - 13.125rem)"
                      }`,
                    }}
                  >
                    <div
                      className="overflow-y-auto"
                      style={{
                        maxHeight: `${
                          isMobile
                            ? "calc(100vh - 9.5rem)"
                            : "calc(100vh - 15.75rem)"
                        }`,
                      }}
                    >
                      <SearchResultsPages results={resultMap.pageResults} />
                    </div>
                  </div>
                )}

                {resultsCategoryState === "news" && (
                  <div
                    className=" py-4 bg-white"
                    style={{
                      maxHeight: `${
                        isMobile
                          ? "calc(100vh - 10rem)"
                          : "calc(100vh - 13.125rem)"
                      }`,
                    }}
                  >
                    <div
                      className="overflow-y-auto"
                      style={{
                        maxHeight: `${
                          isMobile
                            ? "calc(100vh - 9.5rem)"
                            : "calc(100vh - 15.75rem)"
                        }`,
                      }}
                    >
                      <SearchResultsNews results={resultMap.newsResults} />
                    </div>
                  </div>
                )}

                {resultsCategoryState === "products_and_more" && (
                  <div
                    className=" py-4 bg-white"
                    style={{
                      maxHeight: `${
                        isMobile
                          ? "calc(100vh - 10rem)"
                          : "calc(100vh - 13.125rem)"
                      }`,
                    }}
                  >
                    <div
                      className="overflow-y-auto"
                      style={{
                        maxHeight: `${
                          isMobile
                            ? "calc(100vh - 9.5rem)"
                            : "calc(100vh - 15.75rem)"
                        }`,
                      }}
                    >
                      <div className="block-w-container">
                        <div className="flex flex-row flex-wrap -mx-grid">
                          <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
                            <SearchResultsCategories
                              results={resultMap.categoryResults}
                            />
                          </div>
                          <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
                            {/*
                          <SearchResultsAll />
                          */}

                            <SearchResultsProducts
                              results={resultMap.productResults}
                            />
                            <SearchResultsVariants
                              results={resultMap.variantResults}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    )
  }
)

export default SearchResultsOverlay

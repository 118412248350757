import React, { useEffect, useRef, useState } from "react"

const ProductAmountStepper = ({
  id,
  label,
  inputValue,
  min,
  max,
  step,
  onChange = null,
  onDecrement = null,
  onIncrement = null,
  controlDisabled = false,
  className = null,
}) => {
  const [value, setValue] = useState(inputValue)
  const lastValidValue = useRef(inputValue)

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (value !== "" && inputValue !== value && onChange) {
      onChange(value)
    }
    if (value !== "") {
      lastValidValue.current = value
    }
  }, [value])

  const onValueChange = (value) => {
    const val = value === "" ? "" : Math.max(min, Math.min(max, value))
    setValue(val)
  }

  const onBlur = () => {
    value === "" && setValue(lastValidValue.current)
  }

  const onFocus = (e) => {
    if (e?.target) {
      e.target.select()
    }
  }

  const increase = () => {
    const val = Math.min(max, value + step)
    if (onIncrement) {
      onIncrement(val)
    }
    setValue(val)
  }

  const decrease = () => {
    const val = Math.max(min, value - step)
    if (onDecrement) {
      onDecrement(val)
    }
    setValue(val)
  }

  return (
    <div className={className || ""}>
      {label && (
        <label className="sr-only" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="flex">
        <button
          className="border-l border-b border-t border-gray-100 text-primary text-xl px-3 disabled:bg-white"
          onClick={(e) => decrease()}
          disabled={value <= min || controlDisabled}
          type="button"
        >
          <span
            className={value <= min || controlDisabled ? "text-gray-200" : ""}
          >
            –
          </span>
        </button>
        <input
          id={id}
          className="border-t border-b border-gray-100 w-10 py-3 text-center focus:outline-none disabled:bg-white disabled:text-gray-200"
          type="number"
          value={value}
          min={min}
          max={max}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={controlDisabled}
          onChange={(e) => onValueChange(e.target.value)}
        />
        <button
          className="border-r border-b border-t border-gray-100 text-primary text-xl px-3 disabled:bg-white"
          onClick={(e) => increase()}
          disabled={value >= max || controlDisabled}
          type="button"
        >
          <span
            className={value >= max || controlDisabled ? "text-gray-200" : ""}
          >
            +
          </span>
        </button>
      </div>
    </div>
  )
}

export default ProductAmountStepper

import React, { useContext, useEffect } from "react"
// import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/Seo"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Footer from "./Footer"

import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"
import { changeLanguage } from "i18next"

// should default to https://rothenberger.com/privacy

import { privacyLinks } from "gatsby-theme-rothenberger/src/utilities/privacy-links"

const Layout = ({ children, language }) => {
  const { navData } = useContext(NavContext) || { navData: {} }

  // set i18n-language
  // set klaro-language
  useEffect(() => {
    changeLanguage(language)
    if (typeof window !== "undefined") {
      if (window.klaro && window.klaro.render && window.klaroConfig) {
        //window.klaro.defaultConfig.lang = language
        let countryLang = window.location.pathname.split("/")[1]

        setTimeout(() => {
          if (countryLang !== "de-de") {
            window.klaroConfig.services = window.klaroConfig.services.filter(
              (el) => el.name !== "adformtracking"
            )
            window.klaroConfig.services = window.klaroConfig.services.filter(
              (el) => el.name !== "facebookpixel"
            )
          }
          window.klaro.render({
            ...window.klaroConfig,
            ...{ lang: language },
            ...{ privacyPolicy: { default: privacyLinks[countryLang] } },
          })
          if (window.klaro.getManager) {
            let manager = window.klaro.getManager()
            manager.applyConsents()
          }

          if (document?.location?.pathname === "/storyblok-preview/") {
            setTimeout(() => {
              if (document.querySelector(".cm-btn.cm-btn-success")) {
                document.querySelector(".cm-btn.cm-btn-success").click()
              }
            }, 100)
          }
        }, 50)
      }
    }
  }, [language, children])

  return (
    <>
      <Header navData={navData?.mainNavigation?.navigation} />
      {/* <SEO title="Test"></SEO> */}
      <Helmet
        htmlAttributes={{ lang: language }}
        bodyAttributes={{
          class: "theme-default",
        }}
      >
        <script
          type="text/plain" // @TODO: klaro integration, move GTM-KEY to env files
          //type="application/javascript"
          data-name="googletagmanager"
          data-type="application/javascript"
        >
          {` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','G-VD0SKM9H3Z');`}
        </script>
      </Helmet>
      <main>{children}</main>
      <Footer navData={navData?.footer} />
    </>
  )
}

export default Layout
